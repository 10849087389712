$(document).on("turbolinks:load", function () {
  if ($(".js-booking-schedule-btn").is("*")) {

    document.querySelectorAll('.js-schedule-form').forEach(function(form){
      checkUpdatingStatus($(form));
    });


    $('.js-booking-schedule-btn').on('click', function(e){
      e.preventDefault();
      var form = $(this).parents('.js-schedule-form');
      var form_data = form.serialize();

      submitForm(form, form_data);
    });

    $('.js-activate-form')

    var activateForms = document.getElementsByClassName("js-activate-schedule");

    activateForms.forEach((activateForm) => {
      activateForm.addEventListener(
        "change",
        function () {
          var associatedForm = $(activateForm).parents('.js-schedule-wrapper').find('.js-schedule-form');

          disableFields(associatedForm);
          checkUpdatingStatus(associatedForm);
        },
        false
      );
    });
  }
});

function submitForm(form, form_data) {
  disableFields(form);

  $.ajax({
    url: form.attr("action"),
    beforeSend: function(xhr){
      xhr.setRequestHeader('X-CSRF-Token', localStorage.getItem("bp-x-csrf-token"));
    },
    type: "PATCH",
    data: form_data,
    dataType: "json",
    success: () => {
      checkUpdatingStatus(form);
    },
    error: () => {
      window.notyf.error(
        "Sorry, something went wrong with the update. Please try again."
      );

      enableFields(form);
    }
  });

}

function checkUpdatingStatus(form) {
  $.ajax({
    url: form.data("status"),
    type: "GET",
    dataType: "json",
    success: (response) => {
      if(response.updating === false) {
        enableFields(form);
      } else {
        disableFields(form);
        setTimeout(function () {
          checkUpdatingStatus(form);
          },
          5000
        );
      }
    }
  });
}

function disableFields(form) {
  form.parents('.js-schedule-wrapper').find('input, textarea, button').prop('disabled', true);
  form.find('.js-not-updating').addClass('d-none');
  form.find('.js-updating').removeClass('d-none');
}

function enableFields(form) {
  form.parents('.js-schedule-wrapper').find('input, textarea, button').prop('disabled', false);
  form.find('.js-updating').addClass('d-none');
  form.find('.js-not-updating').removeClass('d-none');
}