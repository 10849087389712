$(document).on("turbolinks:load", function () {

  if ( $('#invoice-builder').is('*') ){
    legacyUpdateTitlesAndID();
    bindChange();

    $('#js-add-line-item').on('after-load', function(){
      legacyUpdateTitlesAndID();
      bindChange();
      $('body').tooltip({
        selector: '[data-toggle="tooltip"]'
      });
    });
  }

});

function legacyUpdateTitlesAndID() {
  var lineItemRow = $(this).closest('.line-item-row');

  var addon_id = lineItemRow.find('.js-addon-id');
  var service_id = lineItemRow.find('.js-service-id');
  var series_id = lineItemRow.find('.js-series-id');
  var package_id = lineItemRow.find('.js-package-id');
  var select = $(this).find(':selected');
  var type = select.data('type');

  if (select.data('title') != 'undefined') {
    var title_text = select.data('description');
    var price = select.data('price');

    select.parents('tr').first().find('.title-field').val(title_text);
    select.parents('tr').first().find('.unit-price').val(price);

    if (select.val() == 'custom') {
      select.parents('tr').first().find('.qty').attr('data-original-title', '');
    } else if (select.val() == 'gc') {
      select.parents('tr').first().find('.qty').attr('data-original-title', 'Used to calculate total value.');
    } else {
      var tooltip = ''
      switch (type) {
        case 'addon':
          tooltip = 'The number of credits created for '+ select.data('addon-name') +' addon(s).'
          break;

        case 'service':
          tooltip = 'The number of credits created for '+ select.data('service-name') +' bookings.'
          break;

        case 'series':
          tooltip = 'A quantity of 1 will provide enough credits for all bookings within a '+ select.data('series-name') +' Series.'
          break;

        case 'package':
          tooltip = 'A quantity of 1 will provide all credits within the '+ select.data('package-name') +' package.'
          break;
      }

      select.parents('tr').first().find('.qty').attr('data-original-title', tooltip);
    }
  }

  $(addon_id).val('');
  $(service_id).val('');
  $(series_id).val('');
  $(package_id).val('');

  if (type == 'addon') {
    addon_id.val(select.val());
  } else if (type == 'service') {
    service_id.val(select.val());
  } else if (type == 'series') {
    series_id.val(select.data('series-id'));
  } else if (type == 'package') {
    package_id.val(select.val());
  }

  refreshTotals();
}

function refreshTotals() {
  window.lastSubtotal = $('.subtotal-field').html();
  var lineItems = $('.line-item-row:visible');
  var lineItemsSubtotal = 0.00;
  var subTotalField = $('#invoice-builder .subtotal-field');
  var creditField = $('#invoice-builder .credit-field');
  var totalField = $('#invoice-builder .total-field');
  var discountField = $('#invoice-builder .discount-field');
  var discount = parseFloat(discountField.val());
  var taxableSubtotal = 0.0;
  var discountableSubtotal = 0.0;
  var taxRate = 0.0;
  var currencySymbol = $('#invoice-builder').data('currency-symbol');
  var inclusiveTaxTotal = 0.0;
  var taxTotal = 0.0;

  $.each(lineItems, function(index, lineItemRow){
    var lineItem = $(this);
    var discountable = lineItem.data('discountable');

    var qty = parseFloat(lineItem.find('input.qty').val());
    var price = parseFloat(lineItem.find('input.unit-price').val());

    if (!isNaN(qty) && !isNaN(price)) {
      var lineTotal = qty * price;

      lineItemsSubtotal += lineTotal;

      if(discountable) {
        discountableSubtotal += lineTotal;
      }
    }
  });

  if(creditField.is('*')) {
    creditedAmount = creditField.data('creditedAmount');
  } else {
    creditedAmount = 0;
  }

  if($('.js-discount-by-code').hasClass('active')){

  } else {
    var discount_by_percent = $('#invoice-builder .discount-by-percent').is(':checked');
  }

  if (discount > 0) {
    if (discount_by_percent) {
      var discount_value = (discountableSubtotal - creditedAmount) * (discount/100);
    } else {
      var discount_value = discount;
    }
  } else {
    discount_value = 0;
  }

  subTotalField.html(currencySymbol + lineItemsSubtotal.toFixed(2));

  if ($('#invoice-builder .tax-total-field').is('*')) {
    var taxTotalFields = $('#invoice-builder .tax-total-field');

    // calculate and display tax
    $.each(taxTotalFields, function(index, taxTotalField){
      let tax_subtotal = 0.0;
      let tax_description = $(taxTotalField).data('tax-rate-name');

      $.each(lineItems, function(index, lineItemRow){
        let lineItem = $(lineItemRow);
        let taxRates = lineItem.data('tax-rates-as-json')
        let matchingTaxRate = taxRates.find(tax => tax.description === tax_description);
        let qty = parseFloat(lineItem.find('input.qty').val());
        let price = parseFloat(lineItem.find('input.unit-price').val());
        let discountField = $('#invoice-builder .discount-field');
        let discount = parseFloat(discountField.val());
        let discountable = lineItem.data('discountable');
        let lineItemCreditedAmount = parseFloat(lineItem.data('creditedAmount'));

        if(matchingTaxRate !== undefined && !isNaN(qty) && !isNaN(price)) {
          let lineTotal = qty * price;
          let lessDiscount = 0.0;

          if(discountable && discount > 0.0) {
            let discountablePercentage = (parseFloat(lineTotal) / parseFloat(discountableSubtotal));

            if (discount_by_percent) {
              lineTotal = lineTotal - parseFloat(lineTotal * (discount/100.0));
            } else {
              lessDiscount = parseFloat(discountablePercentage * discount);
            }
          }

          tax_subtotal += (lineTotal - lineItemCreditedAmount - lessDiscount) * (parseFloat(matchingTaxRate.rate) / 100.0);
        }
      });
 
      $(taxTotalField).html(currencySymbol + tax_subtotal.toFixed(2));
      taxTotal += tax_subtotal;

      if($(taxTotalField).data('tax-inclusive') == true) {
        inclusiveTaxTotal += tax_subtotal.toFixed(2);
      }
    });

    setDiscountMax(discountField, discountableSubtotal, discount_by_percent);

    total = (taxTotal - inclusiveTaxTotal) + (lineItemsSubtotal - discount_value - creditedAmount);

  } else {
    setDiscountMax(discountField, lineItemsSubtotal, discount_by_percent);
    total = (lineItemsSubtotal - discount_value - creditedAmount);
  }

  totalField.html(currencySymbol + total.toFixed(2));

  processingFeeField = $('#invoice-builder .processing-fee-field');

  if(processingFeeField.is('*')) {
    $.getJSON('/business/invoices/get_processing_fee.json?total=' + total.toFixed(2), function(data) {
      processingFeeField.html(currencySymbol + data['processing_fee'].toFixed(2));
    });
  }

  var newSubtotal = $('.subtotal-field').html();

  if($('.js-remove-applied-discount').is(':visible') && window.lastSubtotal != newSubtotal && window.refreshingDiscount != true){
    console.log("Refreshing discount ...")
    console.log("window.refreshingDiscount", window.refreshingDiscount)
    setTimeout((function() {
      $('.discount-code-field-btn').trigger("click");
    }), 400);
    window.refreshingDiscount = true;      
  }    

}

function setDiscountMax(discountField, subtotal, discount_by_percent) {
  if (discount_by_percent) {
    discountField.prop('max', '100');
  } else {
    creditedDiscountableAmount = 0;

    $.each($('.line-item-row[data-credited-amount]'), function(index, elem){
      creditedDiscountableAmount += parseFloat($(this).data('creditedAmount'));
    });

    discountField.prop('max', subtotal-creditedDiscountableAmount);
  }
}

function bindChange() {
  var currencySymbol = $('#invoice-builder').data('currency-symbol');

  $('.js-remove-line-item').on('click', function(e){
    e.preventDefault();
    e.stopPropagation();

    var container_row = $(this).closest("tr");
    var title_field = container_row.find('.title-field');

    if(title_field.val() == ''){
      title_field.val('Removed');
    }

    if (container_row.find('.js-line-item-id').val() > 0) {
      container_row.hide();
      container_row.find('.js-destroy-check').val(true);
    } else {
      container_row.remove();
    }

    refreshTotals();
  });

  $('.js-edit-line-item').on('click', function(e){
    e.preventDefault();
    e.stopPropagation();

    var lineItemEditClass = 'edit-line-item-row-' + Date.now();
    window.lineItemEditClass = '.' + lineItemEditClass;

    $(this).closest('.line-item-row').addClass(lineItemEditClass);

    $('#line-builder-modal').modal();
  });

  $('#js-invoice-add-line-item').on('click', function(e){
    window.lineItemEditClass = '';
  });

  $('.invoice-line-item-select').on('change', legacyUpdateTitlesAndID);

  $('body').on('click', '.js-discount-by-code', function(event) {
    $('.discount-field').hide();
    $('.discount-code-field-btn, .discount-code-field').show();
  });

  $('body').on('click', '.js-discount-by-value, .js-discount-by-percent', function(event) {
    $('.discount-field').show();
    $('.discount-code-field-btn, .discount-code-field').hide();
  });

  $('body').on('click', '.discount-code-field-btn', function(event) {
    var discountCode = $('.discount-code-field').val();
    var clientId = $('#invoice-builder').data('clientId');

    $.getJSON('/business/invoices/get_client_discount_code.json?code_to_apply=' + discountCode + "&client_id=" + clientId + "&" + $('#invoice-builder').serialize(), function(data) {
      window.refreshingDiscount = true;

      if(data.success){
        // Clear any validations
        $('.discount-code-field').css('border-color', '');
        $('.discount-code-field-btn').removeClass('btn-outline-danger');
        $('.discount-code-field-btn').addClass('btn-outline-primary');

        $('.js-new-discount-fields').hide();
        $('.js-discount-description').html(data.code);
        $('.js-discount-code-for-submit').val(data.code);
        $('.js-applied-discount-amount').html(data.discount_amount.toFixed(2));
        $('.discount-field').val(data.discount_amount.toFixed(2));
        $('.discount-field').trigger('change')          
        $('.js-applied-discount').show();
      } else {
        $('.discount-code-field').css('border-color', 'red');
      }

      window.refreshingDiscount = false;
    });

    event.preventDefault();
    event.stopPropagation();
  });

  $('body').on('click', '.js-remove-applied-discount', function(event) {
    $('.discount-code-field').val('');

    // Invoice form values we want to send with the create/update POST
    $('.js-clear-saved-discount-code-for-submit').val('');
    $('.js-applied-discount').hide();

    $('.discount-field').val('');
    $('.discount-field').trigger('change');

    // Note: It's important this is shown last; the change above needs to occur first
    $('.js-new-discount-fields').show();
    event.preventDefault();
  });

  $('.invoice-line-item-select, .line-item-row, .line-item-row input.unit-price, .line-item-row input.qty, .discount-field, .discount-by-percent, .discount-by-value, .discount-by-code').on('click change keyup', function(){
    var trScope = $(this).closest("tr");
    var lineItemTotalField = trScope.find('.line-item-total-field');

    var qty = parseFloat(trScope.find('input.qty').val());
    var price = parseFloat(trScope.find('input.unit-price').val());
    var lineItemTotal = qty * price;

    if (isNaN(lineItemTotal)) {
      lineItemTotalField.html(currencySymbol + "0.00");
    } else {
      lineItemTotalField.html(currencySymbol + lineItemTotal.toFixed(2));
    }
    var total = 25.0;

    refreshTotals();
  });
}