$(document).on("turbolinks:load", function () {
  if ($("#js-custom-filters-form").is("*")) {
    bindFilters();
    submitQuery();
    
    $('#save-segment-form').on('submit', function(){
      var form = $('#save-segment-form');
      var segment_form = $("#js-custom-filters-form");
      var form_data = segment_form.serialize() + '&' + form.serialize();

      $.ajax({
        type: form.data('method'),
        url: form.prop('action'),
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "X-CSRF-Token",
            localStorage.getItem("bp-x-csrf-token")
          );
        },
        dataType: "json",
        data: form_data,        
        success: function(data) {
          window.location = data.url;
        },
        error: function(data) {
          window.notyf.error({
            message: "Unable to save Segment",
            duration: 4500
          });
        },
    
      });

    });

    $('select.js-object-field').each(function(){
      displayFilterOptions($(this), false);
    });

    $('select.js-select-filter-parameter').each(function(){
      // Because we have multiple instances of the same value with different data attributes, we need to ensure we're selecting the correct value
      var element = $(this);
      var value = element.val();
      var parentMenu = element.parent('.js-attribute-group').find('select.js-select-filter-attribute');
      var valueType = $('option:selected', parentMenu).data('type');

      $('option[data-parameter-input-type='+valueType+'][value='+value+']', element).prop('selected', true).change();

      updateValueInputs(element, false);
    });

    $('.js-segment-export-btn').on('click', function() {
      var button = $(this);
      requestCSV(button);
    })

    $('select.js-select-filter-attribute, select.js-select-filter-parameter, select.js-filter-group-param-select-value').each( function(){
      var element = $(this);
      var parent = element.data('parent');

      if (parent) {
        var parentMenu = element.parents('.js-attribute-group').find('select.'+parent);

        // determine what to reference 
        if (parentMenu.hasClass('js-select-filter-attribute')) {
          var value = $('option:selected', parentMenu).data('type');
        } else {
          var value = $('option:selected', parentMenu).val();
        }
  
        setTimeout(function(){
          var dropdownMenu = element.parent().find('.dropdown-menu');      
  
          element.parent().find('.dropdown-toggle').on('click.load', function() {
            filterOptions(dropdownMenu, value);
          });
        }, 100);
  
      }
    });
  }
});

function bindFilters() {
  setTimeout(function(){
    $('.js-add-filter-group-params, .js-add-filter-group').off('click.filters').on('click.filters', function(){
      bindFilters();
      showSave();
    });

    $("select.js-object-field").off('change.filters').on('change.filters', function() {
      displayFilterOptions($(this), true);
      submitQuery();
      showSave();
    });
  
    $("select.js-select-filter-attribute").off('change.filters').on('change.filters', function() {
      displayFilterOptions($(this), true);
      submitQuery();
      showSave();
    });

    $('select.js-select-filter-group-operator').off('change.filters').on('change.filters', function() {
      updateConditions($(this));  
      showSave();
    });
  
    $('select.js-select-filter-group-params-operator').off('change.filters').on('change.filters', function() {
      updateParamsConditions($(this));  
      showSave();
    });
  
    $('select.js-select-filter-parameter').off('change.filters').on('change.filters', function() {
      updateValueInputs($(this), true);
      submitQuery();
      showSave();
    });

    $('select.js-filter-group-param-select-value').off('change.filters').on('change.filters', function() {
      submitQuery();
      showSave();
    });

    $('.js-delete-param-fields').off('click.filters').on('click.filters', function(event){
      event.preventDefault();
      removeFilterFields($(this));
      showSave();
    });
    
    $('.value-fields input').off('change.filters').on('change.filters', function() {
      submitQuery();
      showSave();
    });

    window.loadFlatpickr();
  }, 100);

}

function submitQuery() {
  var form = $("#js-custom-filters-form");

  $('#js-results-target').empty();
  $('#js-error-container').addClass('d-none');
  $('#js-loader').show();

  $.ajax({
    type: "POST",
    url: form.data('query-url'),
    beforeSend: function (xhr) {
      xhr.setRequestHeader(
        "X-CSRF-Token",
        localStorage.getItem("bp-x-csrf-token")
      );
    },
    dataType: "script",
    data: form.serialize(),        
    success: function(data) {
      // window.notyf.success({
      //   message: "Got results!",
      //   duration: 4500
      // });
    },
    error: function(data) {
      $('#js-loader').hide();
      $('#js-error-container').removeClass('d-none');
    },

  });
}

function requestCSV(button) {
  var form = $("#js-custom-filters-form");
  
  $.ajax({
    type: "POST",
    url: button.data('export-url') + '.csv',
    beforeSend: function (xhr) {
      xhr.setRequestHeader(
        "X-CSRF-Token",
        localStorage.getItem("bp-x-csrf-token")
      );
    },
    dataType: "script",
    data: form.serialize(),        
    success: function(data) {
      window.location = button.data('redirect');
    },
    error: function(data) {
      window.notyf.error({
        message: "Error exporting results",
        duration: 4500
      });
    },

  });
}

function showSave() {
  $('.save-segment-btn').removeClass('d-none');
}

function removeFilterFields(element) {
  var container = element.closest(".js-attribute-group");
  var parentContainer = container.closest('.js-filter-group')

  if (container.find('.destroy-check').is('*')) {
    container.addClass('d-none').removeClass('d-flex');
    container.removeClass('js-attribute-group');
    container.find('.destroy-check').val(true);
    console.log(container.find('.destroy-check').val())
    container.find('input, select').each(function(){
      if (!$(this).hasClass('destroy-check') && !$(this).hasClass('object-id') ) {
        $(this).prop('disabled', true);
      }
    });

  } else {
    container.remove();
  }

  if (parentContainer.find('.js-attribute-group').length == 0) {
    if (parentContainer.find('.destroy-check').is('*')) {
      parentContainer.addClass('d-none').removeClass('d-flex');
      parentContainer.find('.destroy-check').val(true);
      parentContainer.find('input, select').each(function(){
        if (!$(this).hasClass('destroy-check') && !$(this).hasClass('object-id')) {
          $(this).prop('disabled', true);
        }
      });

    } else {
      parentContainer.remove();
    }
  }

  submitQuery();
}

function updateConditions(element) {
  // this updates all Group Operator values to be the same when one is changed
  var groupParent = element.parents('form');
  var selectedValue = element.val();

  groupParent.find('.js-select-filter-group-operator').val(selectedValue);
  groupParent.find('.js-select-filter-group-operator').selectpicker('refresh');

  submitQuery();
}

function updateParamsConditions(element) {
  // this updates all Params Group Operator values to be the same when one is changed
  var groupParent = element.parents('.js-filter-group-params');
  var selectedValue = element.val();

  groupParent.find('.js-select-filter-group-params-operator').val(selectedValue);
  groupParent.find('.js-select-filter-group-params-operator').selectpicker('refresh');

  submitQuery();
}

function displayFilterOptions(element, resetValue) {
  var child = element.data('child');
  var childMenu = element.parents('.js-attribute-group').find('select.'+child);

  // determine what to reference 
  if (element.hasClass('js-select-filter-attribute')) {
    var value = $('option:selected', element).data('type');
  } else {
    var value = $('option:selected', element).val();
  }

  var inputId = childMenu.attr('id');
  var conditionButton = $('[data-id='+inputId);
  var conditionWrapper = conditionButton.parent();
  var dropdownMenu = conditionWrapper.find('.dropdown-menu');

  if (resetValue) {
    // clear condition value if present
    childMenu.val('').selectpicker('refresh');
  }

  if (value) {
    conditionWrapper.removeClass('d-none');
  } else {
    conditionWrapper.addClass('d-none');
  }

  conditionButton.on('click', function() {
    filterOptions(dropdownMenu, value)
  });

  if (childMenu.data('values')) {
    // hide and disable all inputs
    var fieldWrapper = childMenu.parents('.js-attribute-group').find('.value-fields');
    fieldWrapper.find('input, select').addClass('d-none');
    fieldWrapper.find('.js-filter-group-param-date-integer, .input-group').addClass('d-none');
    fieldWrapper.find('input, select').prop('disabled', true);
  }

  // traverse other children and reset values
  if (childMenu.data('child')) {
    displayFilterOptions(childMenu, resetValue);
  }
}

function updateValueInputs(element, resetValue) {
  var valueType = $('option:selected', element).data('parameter-input-type');

  // hide and disable all inputs
  var fieldWrapper = element.parents('.js-attribute-group').find('.value-fields');
  var valueSelect = fieldWrapper.find('select.js-filter-group-param-select-value');
  fieldWrapper.find('input, select').addClass('d-none');
  fieldWrapper.find('.js-filter-group-param-date-integer, .input-group').addClass('d-none');
  fieldWrapper.find('input, select').prop('disabled', true);

  if (resetValue) {
    fieldWrapper.find('input, select').val('');
  }

  // determine if input should be enabled and displayed
  switch (valueType) {
    case 'string':
      fieldWrapper.find('.js-filter-group-param-string-value').prop('disabled', false);
      fieldWrapper.find('.js-filter-group-param-string-value').removeClass('d-none');
      break;
    case 'boolean':
      // Fields already hidden, no value display needed
      break;
    case 'date':
      fieldWrapper.find('.js-filter-group-param-date').prop('disabled', false);
      fieldWrapper.find('.js-filter-group-param-date').removeClass('d-none');
      break;
    case 'date-integer':
      fieldWrapper.find('.js-filter-group-param-date-integer input').prop('disabled', false);
      fieldWrapper.find('.js-filter-group-param-date-integer').removeClass('d-none');
      fieldWrapper.find('.js-filter-group-param-date-integer input').removeClass('d-none');
      break;
    case 'number':
      fieldWrapper.find('.js-filter-group-param-number-value').prop('disabled', false);
      fieldWrapper.find('.js-filter-group-param-number-value').removeClass('d-none');
      break;
    case 'decimal':
      fieldWrapper.find('.js-filter-group-param-decimal-value').prop('disabled', false);
      fieldWrapper.find('.js-filter-group-param-decimal-value').removeClass('d-none');
      break;
    default:
      disableAndShowValue(valueSelect, valueType, resetValue);
      break;  
  }
}

function disableAndShowValue(element, value, resetValue) {
  element.prop('disabled', false);

  if (resetValue) {
    // clear slected value
    element.val('').selectpicker('refresh');
  } else {
    element.selectpicker('refresh');
  }

  // show select
  element.parent().removeClass('d-none');

  var dropdownMenu = element.parent().find('.dropdown-menu');

  element.parent().find('.dropdown-toggle').on('click', function() {
    filterOptions(dropdownMenu, value);
  });

}

function filterOptions(dropdownMenu, value) {
  setTimeout( function(){
    // hide all options not applicable
    dropdownMenu.find('li').addClass('d-none');

    if (value) {
      var classes = dropdownMenu.find('span.text')
        .filter( function(){ return $(this).text().toLowerCase() === value.replace(/_/g, " "); })
        .parent().attr('class').split(' ');
      
      var optionGroup = $.grep(classes, function(elem){ return elem.indexOf('optgroup') > -1; })[0]
      
      dropdownMenu.find('li.'+optionGroup).removeClass('d-none');
      if (dropdownMenu.find('li.'+optionGroup+'div').length > 1) {
        dropdownMenu.find('li.'+optionGroup+'div').first().removeClass('d-none');
      }
      dropdownMenu.find('li.dropdown-header.'+optionGroup).addClass('d-none');
    }    
  }, 50);
}